import axios from "axios";

const urlBase = "https://gateway-admin-plus.sistemainteegra.com.br/api-admin-mobile";
const urlBaseFiles = "https://gateway-admin-plus.sistemainteegra.com.br/api-admin-files";

function getFunctions(participanteId, eventId) {
  var config = {
    method: "get",
    url: `${urlBase}/functions/login/${participanteId}/event/${eventId}/released`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  return axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

function readFunctions(participanteId, functionId, eventId) {
  var data = JSON.stringify({
    participanteId,
    functionId,
    eventId,
  });
  var config = {
    method: "post",
    url: `${urlBase}/functions/release`,
    headers: {
      "Content-Type": "application/json",
    },
    data,
  };

  return axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

function postDownload(eventId, loginId, fileId, fileName) {
  var data = JSON.stringify({
    eventId,
    loginId,
    fileId,
    fileName,
  });

  var config = {
    method: "post",
    url: `${urlBaseFiles}/download`,
    headers: {
      "Content-Type": "application/json",
    },
    data,
  };

  return axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

export { getFunctions, readFunctions, postDownload };
